import React from "react";
import {Link} from "react-router-dom";

import logo64 from "../assets/images/logo-icon-64x64.png"

export default function Footer() {
    return (
        <footer className="bg-footer">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="footer-py-60 footer-border">
                            <div className="row">
                                <div>
                                    <p className="mt-4 text-center">Copyright © 2022 <a href="mailto:ckbang@everyshare.net">EveryShare Co., Ltd.</a> All rights reserved</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}