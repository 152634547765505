import React,{useState} from "react";

import bg2 from "../assets/images/photography/p17.jpg"
import Navbar from "../componants/navbar";
import Footer from "../componants/footer";

export default function Index(){
    const [isOpen, setOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0)
    return(
        <>
            <Navbar/>

            <section className="section " style={{backgroundImage:`url(${bg2})`, backgroundAttachment:"fixed"}} id="cta">
                <div className="bg-overlay"></div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 text-center">
                            <div className="section-title">
                                <p></p>
                                <h4 className="title title-dark text-white mb-4">우리가 만드는 세상<br/>모두가 즐기는 재미</h4>
                                <p className="text-light para-dark para-desc mx-auto">We make fun for everyone<br/>every generations</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
        </>
    )
}