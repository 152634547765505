import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {Link as Link1} from 'react-scroll';
import logo32 from "../assets/images/logo-icon-32x32.png"

export default function Navbar() {
    const [isMenu, setisMenu] = useState(false);

    const toggleMenu = () => {
        setisMenu(!isMenu);
        if (document.getElementById("navigation")) {
            const anchorArray = Array.from(document.getElementById("navigation").getElementsByTagName("a"));
            anchorArray.forEach(element => {
                element.addEventListener('click', (elem) => {
                    const target = elem.target.getAttribute("href")
                    if (target !== "") {
                        if (elem.target.nextElementSibling) {
                            var submenu = elem.target.nextElementSibling.nextElementSibling;
                            submenu.classList.toggle('open');
                        }
                    }
                })
            });
        }
    };

    return (
        <>
            <header id="topnav" className={`nav-sticky defaultscroll sticky tagline-height`}>
                <div className="container">
                    <Link className="logo" to="/">
                    <span className="logo-light-mode">
                        <img src={logo32} className="l-dark" alt=""/>
                    </span>
                        <img src={logo32} className="logo-dark-mode" alt=""/>
                    </Link>
                    {/*<div className="menu-extras">*/}
                    {/*    <div className="menu-item">*/}
                    {/*        <Link to="#" className={`navbar-toggle ${isMenu ? 'open' : ''}`} id="isToggle"*/}
                    {/*              onClick={() => toggleMenu()}>*/}
                    {/*            <div className="lines">*/}
                    {/*                <span></span>*/}
                    {/*                <span></span>*/}
                    {/*                <span></span>*/}
                    {/*            </div>*/}
                    {/*        </Link>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div id="navigation" style={{display: isMenu ? 'block' : 'none'}}>
                        {/*<ul className="navigation-menu nav-light" id="navmenu-nav">*/}
                        {/*    <li className="has-submenu">*/}
                        {/*        <Link1 to="home" activeClass="active" spy={true} smooth={true} duration={500}*/}
                        {/*               className="sub-menu-item">Home</Link1>*/}
                        {/*    </li>*/}
                        {/*    <li className="has-submenu">*/}
                        {/*        <Link1 to="features" activeClass="active" spy={true} smooth={true} duration={500}*/}
                        {/*               className="sub-menu-item">Feature</Link1>*/}
                        {/*    </li>*/}
                        {/*    <li className="has-submenu">*/}
                        {/*        <Link1 to="about" activeClass="active" spy={true} smooth={true} duration={500}*/}
                        {/*               className="sub-menu-item">About Us</Link1>*/}
                        {/*    </li>*/}
                        {/*    <li className="has-submenu">*/}
                        {/*        <Link1 to="testimonial" activeClass="active" spy={true} smooth={true} duration={500}*/}
                        {/*               className="sub-menu-item">Reviews</Link1>*/}
                        {/*    </li>*/}
                        {/*    <li className="has-submenu">*/}
                        {/*        <Link1 to="news" activeClass="active" spy={true} smooth={true} duration={500}*/}
                        {/*               className="sub-menu-item">Blog</Link1>*/}
                        {/*    </li>*/}
                        {/*    <li className="has-submenu">*/}
                        {/*        <Link1 to="contact" activeClass="active" spy={true} smooth={true} duration={500}*/}
                        {/*               className="sub-menu-item">Contact</Link1>*/}
                        {/*    </li>*/}
                        {/*</ul>*/}
                    </div>
                </div>
            </header>
        </>
    )
}